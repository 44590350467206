import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"
import {
	adList
} from "@/api/website"
import {
	noticesList
} from "@/api/cms/notice"
import {
	floors,
	floatLayer,
	apiDefaultSearchWords
} from "@/api/pc"
import {
	mapGetters
} from "vuex"
import {
	couponList,
	receiveCoupon
} from "@/api/coupon"
import {
	goodsPage,
	timeList
} from "@/api/seckill"
import CountDown from "vue2-countdown"

export default {
	name: "index",
	components: {
		CountDown
	},
	data: () => {
		return {
			loadingAd: true,
			loadingFloor: true,
			adList: [],
			
			adcoup: [],
			adcoup77: [],
			adcoup2: [],
			adcoup3: [],
			adtip: [],
			adtip2: [],
			
			adtaplong: [],
			adLeftList: [],
			adRightList: [],
			couponList: [],
			floorList: [],
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},
			indexFloatLayerNum: 0,
			isSub: false,
			siteId: 0,
			secidd: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "ระยะทางมากกว่า",
			backgroundColor: "", // ด้านบนbannerสีพื้นหลัง
			// การค้นหาแบบลอยตัว
			searchType: "goods",
			searchTypeText: "สินค้า",
			keyword: "",
			defaultSearchWords: "",
			isShow: false
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "สินค้า" : "ร้าน"
		}
	},
	created() {
		this.getAdList()
		this.getBigAdList()
		this.getSmallAdList()
		this.getFloors()
		this.getFloatLayer()
		this.$store.dispatch("site/siteInfo") // ข้อมูลเว็บไซต์
		this.getDefaultSearchWords() // ชนิดการค้นหาเริ่มต้น
		if(this.addonIsExit && this.addonIsExit.seckill == 1){
			this.getTimeList()
		}		
		this.getCanReceiveCouponQuery()
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount"]),
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 2
			}
		}
	},
	methods: {
		countDownS_cb() {},
		countDownE_cb() {
			this.seckillText = "กิจกรรมได้สิ้นสุดลงแล้ว"
		},
		getAdList() {
			adList({
					keyword: "NS_PC_INDEX"
				})
				.then(res => {
					console.log(res,'getAdList')
					this.adList = res.data.adv_list
					this.$store.dispatch("app/is_show", {
						is_show:this.adList.length
					}).then(res => {})
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/**
		 * รูปภาพขนาดใหญ่ของช่องโฆษณา
		 */
		getBigAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_LEFT"
				})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
				adList({
						keyword: "NS_PC_LONG"
					})
					.then(res => {
						this.adtaplong = res.data.adv_list
						for (let i = 0; i < this.adtaplong.length; i++) {
							if (this.adtaplong[i].adv_url) this.adtaplong[i].adv_url = JSON.parse(this.adtaplong[i].adv_url)
						}
						
					})
					.catch(err => {
					
					})
				
					
				
					adList({
							keyword: "NS_PC_COUP"
						})
						.then(res => {
							this.adcoup = res.data.adv_list
							for (let i = 0; i < this.adcoup.length; i++) {
								if (this.adcoup[i].adv_url) this.adcoup[i].adv_url = JSON.parse(this.adcoup[i].adv_url)
							}
							
						})
						.catch(err => {
						
						})
						adList({
								keyword: "NS_SECKILL1"
							})
							.then(res => {
								this.adcoup77 = res.data.adv_list
								for (let i = 0; i < this.adcoup77.length; i++) {
									if (this.adcoup77[i].adv_url) this.adcoup77[i].adv_url = JSON.parse(this.adcoup77[i].adv_url)
								}
								
							})
							.catch(err => {
							
							})
						
						
						adList({
								keyword: "NS_PC_COUP3"
							})
							.then(res => {
								this.adcoup3 = res.data.adv_list
								for (let i = 0; i < this.adcoup3.length; i++) {
									if (this.adcoup2[i].adv_url) this.adcoup2[i].adv_url = JSON.parse(this.adcoup3[i].adv_url)
								}
								
							})
							.catch(err => {
							
							})
							
							
							adList({
									keyword: "NS_PC_TIP"
								})
								.then(res => {
									this.adtip = res.data.adv_list
									for (let i = 0; i < this.adtip.length; i++) {
										if (this.adtip[i].adv_url) this.adtip[i].adv_url = JSON.parse(this.adtip[i].adv_url)
									}
									
								})
								.catch(err => {
								
								})
								
								adList({
										keyword: "NS_PC_TIP2"
									})
									.then(res => {
										this.adtip2 = res.data.adv_list
										for (let i = 0; i < this.adtip2.length; i++) {
											if (this.adtip2[i].adv_url) this.adtip2[i].adv_url = JSON.parse(this.adtip2[i].adv_url)
										}
										
									})
									.catch(err => {
									
									})
				
				
				
				
			
				
				
				
				
				
				
				
				
				
		},
		/**
		 * สกรีนช็อตของตําแหน่งการจัดวางโฆษณา
		 */
		getSmallAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_RIGHT"
				})
				.then(res => {
					this.adRightList = res.data.adv_list
					for (let i = 0; i < this.adRightList.length; i++) {
						if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		//ดูรายการคูปอง
		getCanReceiveCouponQuery() {
			couponList({
					activeName: "second",
					site_id: this.siteId
				})
				.then(res => {
					console.log(res,'ดูรายการคูปอง')
					let data = res.data
					this.couponList = []
					if (data != null) {
						for (let i = 0; i < data.list.length; i++) {
							if (i < 5) {
								this.couponList.push(data.list[i])
							}
						}
						this.couponList.forEach(v => {
							v.useState = 0
						})
					}
				})
				.catch(err => {})
		},
		/**
		 * รับคูปอง
		 */
		receiveCoupon(item, index) {
			console.log(item, index)
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "second",
				coupon_type_id: item.coupon_type_id
			}
			receiveCoupon(data)
				.then(res => {
					console.log(res,'รับคูปอง')
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "เรียกร้องความสําเร็จ"
						this.$notify({
							message: msg,
							title: 'เรียบร้อยแล้ว',title: 'เรียบร้อยแล้ว',type: "success"
						})
					} else {
						console.log('รับคูปอง')
						this.$notify({
							message: msg,
							title: "คำเตือน",type: "warning"
						})
					}
					let list = this.couponList
					if (res.data.is_exist == 1) {
						for (let i = 0; i < list.length; i++) {
							if (list[i].coupon_type_id == item.coupon_type_id) {
								list[i].useState = 1
							}
						}
					} else {
						for (let i = 0; i < list.length; i++) {
							if (list[i].coupon_type_id == item.coupon_type_id) {
								list[i].useState = 2
							}
						}
					}

					this.isSub = false
					this.$forceUpdate()
				})
				.catch(err => {
					if( err.message == 'คุณยังไม่ได้เข้าสู่ระบบ，กรุณาเข้าสู่ระบบก่อน' ){
						this.$router.push('/login');
					} else {
						this.$notify.error({title: 'Error',message: err.message});
					}
					this.isSub = false
				})
		},
		/**
		 * คลิกที่คูปอง
		 */
		couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * ไปซื้อ
		 */
		toGoodsList(item) {
			if (item.use_scenario != 1) {
				this.$router.push({
					path: "/list",
					query: {
						platformcouponTypeId: item.platformcoupon_type_id
					}
				})
			} else {
				this.$router.push("/list")
			}
		},
		/**
		 * ข้อเสนอแฟลชแบบจํากัดเวลา
		 */
		getTimeList() {
			timeList()
				.then(res => {
					if (res.code == 0 && res.data) {
						let time = new Date(res.timestamp * 1000)
						let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()

						res.data.list.forEach((v, k) => {
							if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
								let seckillId = v.id
								
								this.secidd = v.id
								this.getGoodsList(seckillId)

								let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
								this.seckillTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: endTime
								}
							}
						})
					}
				})
				.catch(err => {
					// this.$notify.error({title: 'Error',message: err.message});
				})
		},
		/**
		 * สินค้าสายฟ้าแลบ
		 */
		getGoodsList(id) {
			goodsPage({
					page_size: 10,
					page: 1,
					seckill_id: id,
					seckill_time_id: this.secidd,
					site_id: this.siteId
				})
				.then(res => {
					if (res.code == 0 && res.data.list) {
						this.listData = res.data.list
						
						this.listData.forEach(v => {
							v.goods_image = v.goods_image.split(',')[0]
						})
						
					}
				})
				.catch(err => {})
		},
		/**
		 * โหลดรูปภาพไม่สําเร็จ
		 */
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},
		
		/**
		 * โหลดรูปภาพไม่สําเร็จ
		 */
		adLeftImageError(index) {
			this.adLeftList[index].adv_image = this.defaultGoodsImage
		},
		/**
		 * โหลดรูปภาพไม่สําเร็จ
		 */
		adRightImageError(index) {
			this.adRightList[index].adv_image = this.defaultGoodsImage
		},
		getFloors() {
			floors()
				.then(res => {
					this.floorList = res.data;
				})
				.catch(err => {
					console.log(err)
				})
		},
		getFloatLayer() {
			floatLayer()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.floatLayer = res.data	
						if(this.floatLayer.is_show == 1){
							this.floatLayer.link = JSON.parse(this.floatLayer.url)
							// กรอบป็อปอัพ，การดีดออกครั้งแรก 1，ทุกครั้งที่มันปรากฏขึ้น 0
							if (!this.floatLayer.img_url) return
							var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;						
							if (parseInt(this.floatLayer.number) >= 1) {												
								//จํานวนแคช == จํานวนป๊อปอัปทั้งหมด   ห้ามดีดออก						
								if(index_popwindow_count >= parseInt(this.floatLayer.number)){							
									this.floatLayer.is_show_type = false
								}else{
									this.floatLayer.is_show_type = true
								}							
							} else if (parseInt(this.floatLayer.number) == 0) {
								this.floatLayer.is_show_type = true							
							}
						}else{
							this.floatLayer.is_show_type = false	
						}
						
					}
				})
				.catch(err => err)
		},
		closeFloat() {
			var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;
			if(parseInt(this.floatLayer.number) == 0){
				this.$store.commit("app/SET_FLOAT_LAYER", 0)
			}else if(parseInt(this.floatLayer.number) >= 1 && index_popwindow_count != parseInt(this.floatLayer.number)) {
				var count_num = index_popwindow_count + 1;
				this.$store.commit("app/SET_FLOAT_LAYER", count_num)
			}else if(index_popwindow_count == parseInt(this.floatLayer.number)){
				this.$store.commit("app/SET_FLOAT_LAYER", this.floatLayer.number)
			}
			this.floatLayer.is_show_type = false
			this.$forceUpdate()
			// this.$store.commit("app/SET_FLOAT_LAYER", -1)
		},
		// ฟังแถบเลื่อน
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// การออกจากหน้านี้จําเป็นต้องลบเหตุการณ์การฟัง，มิฉะนั้นจะมีการรายงานข้อผิดพลาด
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * ชนิดการค้นหาเริ่มต้น（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		handleCommand(command) {
			this.searchType = command
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		}
	}
}
