<template>
	<div class="floor-style-3">
		<div class="item-wrap">
			<div class="head-wrap">
				<div class="title-name" v-if="data.value.title.value.text">
					<span :style="{ backgroundColor: data.value.title.value.color }"></span>
					<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }" style="    font-size: 30px;
    font-family: 'Prompt';
    font-weight: 600;">{{ data.value.title.value.text }}</h2>
				</div>
				<div class="category-wrap">
					<li v-for="(item, index) in data.value.categoryList.value.list" :key="index">
						<router-link target="_blank" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{ item.category_name }}</router-link>
					</li>
				</div>
			</div>
			<div class="body-wrap">
				<div class="left-img-wrap" style="display: none;" >
					<img v-if="data.value.leftImg.value.url" style="display: none;" :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
				</div>
				  <el-carousel :interval="5000" arrow="arrow" :autoplay="true" indicator-position="outside">
				    <el-carousel-item v-for="item2 in Math.ceil(data.value.rightGoodsList.value.list.length/5)" :key="item2" >
				  <div class="product-card__wrapper items-1-59"   style="justify-content: flex-start; display: flex; padding-top: 20px;" >
				  	<div  class="product-card" style="background: rgb(255, 255, 255); padding: 10px; border-radius: 15px;"  v-for="(item, index) in data.value.rightGoodsList.value.list" :key="index" @click="goSku(item.sku_id)" v-if="(item2-1)*5<=index&&index<item2*5" >
				  		<div class="product__image__wrapper"> 
				  			<a class="product__image"><img :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageErrorRight(index)" alt="icon" style="width: 100%; height: 100%;"></a>
				  			<div class="badge">-50</div>
				  			<div class="product__actions">
				  				<a href="#" class="action__btn">
				  					<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				  						<path d="M13.8931 2.07333C13.5526 1.73267 13.1483 1.46243 12.7033 1.27805C12.2584 1.09368 11.7814 0.998779 11.2998 0.998779C10.8181 0.998779 10.3412 1.09368 9.89618 1.27805C9.45121 1.46243 9.04692 1.73267 8.70642 2.07333L7.99975 2.78L7.29309 2.07333C6.60529 1.38553 5.67244 0.999136 4.69975 0.999136C3.72706 0.999136 2.79422 1.38553 2.10642 2.07333C1.41863 2.76112 1.03223 3.69397 1.03223 4.66666C1.03223 5.63935 1.41863 6.5722 2.10642 7.26L2.81309 7.96666L7.99975 13.1533L13.1864 7.96666L13.8931 7.26C14.2337 6.91949 14.504 6.51521 14.6884 6.07023C14.8727 5.62526 14.9676 5.14832 14.9676 4.66666C14.9676 4.185 14.8727 3.70807 14.6884 3.26309C14.504 2.81812 14.2337 2.41383 13.8931 2.07333V2.07333Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
				  					</svg>
				  				</a>
				  				<a href="#" class="action__btn">
				  					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				  						<path d="M0.666992 7.99996C0.666992 7.99996 3.33366 2.66663 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C15.3337 7.99996 12.667 13.3333 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
				  						<path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
				  					</svg>
				  				</a>
				  				<a href="#" class="action__btn">
				  					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				  						<path d="M10 9.33329L13.3333 5.99996L10 2.66663" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
				  						<path d="M2.66699 13.3333V8.66667C2.66699 7.95942 2.94794 7.28115 3.44804 6.78105C3.94814 6.28095 4.62641 6 5.33366 6H13.3337" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
				  					</svg>
				  				</a>
				  			</div>
				  		</div>
				  		<div class="product__content">
				  			<div class="product__title" style="min-height: 44px; max-height: 44px;">
				  				<h5><a style="font-family: sukhumvittadmai, sans-serif !important;">{{ item.goods_name }}</a></h5></div>
				  			<div class="product__bottom">
				  				<div class="product__price"> ฿30.00 <del>฿45.00</del></div>
				  				<div class="cart__action__btn">
				  					<div class="cart__btn"><a href="#" class="btn btn-outline" style="font-family: sukhumvittadmai, sans-serif !important; background: rgb(235, 94, 40); color: rgb(255, 255, 255);">ซื้อเลย</a></div>
				  					<div class="quantity cart__quantity">
				  						<button type="button" class="decressQnt"><span class="bar"></span></button>
				  						<input type="number" disabled="disabled" value="0" min="01" max="100" class="qnttinput">
				  						<button type="button" class="incressQnt"><span class="bar"></span></button>
				  					</div>
				  				</div>
				  			</div>
				  		</div>
				  	</div>
				  
				  </div>
				    </el-carousel-item>
				  </el-carousel>
				  
				  
				  
				<ul class="right-goods-wrap" style="display: none;"  >
					<li v-for="(item, index) in data.value.rightGoodsList.value.list" :key="index" @click="goSku(item.sku_id)">
						<h4>{{ item.goods_name }}</h4>
						<p class="ns-text-color">{{ item.introduction }}</p>
						<div class="img-wrap"><img alt="รูปภาพสินค้า" :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageErrorRight(index)" /></div>
					</li>
				</ul>
				<ul class="bottom-goods-wrap">
					<li v-for="(item, index) in data.value.bottomGoodsList.value.list" :key="index" @click="goSku(item.sku_id)">
						<div class="info-wrap">
							<h4>{{ item.goods_name }}</h4>
							<p class="ns-text-color">{{ item.introduction }}</p>
						</div>
						<div class="img-wrap"><img alt="รูปภาพสินค้า" :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageErrorBottom(index)" /></div>
					</li>
				</ul>

				<!-- <ul class="brand-wrap">
					<li v-for="(item, index) in data.value.brandList.value.list" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
						<img alt="ภาพแบรนด์" :src="$img(item.image_url)" />
					</li>
				</ul> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-3',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageErrorRight(index) {
			this.data.value.rightGoodsList.value.list[index].goods_image = this.defaultGoodsImage;
		},
		imageErrorBottom(index) {
			this.data.value.bottomGoodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-3 {
	overflow: hidden;

	.item-wrap .head-wrap {
		height: 50px;
		line-height: 50px;
		.title-name {
			display: inline-block;
			span {
				float: left;
				width: 5px;
				height: 21px;
				margin-top: 15px;
			}
			h2 {
				float: left;
				margin-left: 10px;
				font-weight: bold;
				font-size: 20px;
			}
		}
		.category-wrap {
			float: right;
			display: flex;
			li {
				margin-right: 10px;
			}
		}
	}
	.body-wrap {
		.left-img-wrap {
			width: 190px;
			height: 360px;
			float: left;
			cursor: pointer;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		.right-goods-wrap {
			margin-left: 190px;
			text-align: center;
			overflow: hidden;
			li {
				float: left;
				width: 19.9%;
				background: #ffff;
				border-width: 0 0 1px 1px;
				border-color: #f9f9f9;
				border-style: solid;
				cursor: pointer;
				h4 {
					font-size: 14px;
					margin: 10px 20px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 4px 30px;
					height: 20px;
				}
			}

			.img-wrap {
				width: 105px;
				height: 105px;
				line-height: 105px;
				display: inline-block;
				margin-bottom: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
	.bottom-goods-wrap {
		overflow: hidden;
		display: flex;
		li {
			flex: 1;
			background: #fff;
			border-width: 0 0 1px 1px;
			border-color: #f9f9f9;
			border-style: solid;
			cursor: pointer;
			&:first-child {
				border-left: 0;
			}

			.info-wrap {
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				h4 {
					font-size: 14px;
					margin: 0 10px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 90px;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 0 20px;
					width: 70px;
				}
			}
			.img-wrap {
				width: 70px;
				height: 70px;
				line-height: 70px;
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				padding: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.brand-wrap {
		display: flex;
		background: #fff;
		li {
			flex: 1;
			height: 50px;
			cursor: pointer;
			line-height: 50px;
			text-align: center;
			background: #fff;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}




.el-carousel__container {
    position: relative;
    height: 500px;
}.product-card__wrapper.items-1-59 .product-card {
        width: 246px;
    margin: 10px;
}
.product-card .product__content .product__title h5 a {
    color: #252522;
    display: block;
    transition: all 300ms ease-in-out;
    font-weight: 500 !important;
    font-family: 'Prompt' !important;
}.product-card .product__content .product__price {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: inherit;
    font-family: 'sukhumvittadmai2';
    font-size: 23px;
    font-weight: 700;
    line-height: 20px;
    color: #403c39;
}.cart__action__btn .cart__btn .btn {
    width: 54px;
    height: 29px;
    font-family: sukhumvittadmai, sans-serif !important;
    background: rgb(255 0 0) !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 600 !important;
}
</style>
